<template>
  <Authenticated>
    <div class="store-wrapper">
      <CartColumn @loadOrderLineItem="loadOrderLineItem" />

      <div class="main">
        <div v-if="categories.length > 1" class="buttons">
          <div v-for="(cat, idx) in categories.filter((c) => (webStore && c.key.posOnly ? false : c))" :key="cat.id">
            <div v-if="active_products_in_categories(cat).length > 0" class="catergory-select" @click="catScroll(`${idx}`)"><p class="cat-name">{{ cat.key.name }}</p></div>
          </div>
        </div>
        <div v-for="(cat, idx) in categories.filter((c) => (webStore && c.key.posOnly ? false : c))" :key="cat.id">
          <div v-if="active_products_in_categories(cat).length > 0" :id="idx" style="scroll-margin-top: 100px" class="background">
            <h2>{{ cat.key.name }}</h2>

            <span v-if="cat.key.description && cat.key.description !== '<p></p>'" v-html="cat.key.description"></span>

            <ul v-if="!webStore" class="table wrapper layout">
              <router-link :to="`/store/${cat.id.replace(/^.*:/, '')}/${prod.id.replace(/^.*:/, '')}`" class="long-section" v-for="prod in active_products_in_categories(cat)" :key="prod">
                <span v-if="prod.value.soldout" class="soldout" @click="productClick(prod.value)">Sold Out</span>
                <li :class="prod.value.soldout ? 'soldoutProd long-layout' : 'active long-layout'" :key="prod.value.id" @click="productClick(prod.value)">
                  <div v-if="siteSettings.imagesRequired || webStore">
                    <div v-if="prod.value.images && prod.value.images.length > 0">
                      <div>
                        <img loading="lazy" v-if="prod.value.images[0]" :alt="prod.value.name" :src="prod.value.images[0]" />
                        <img v-else class="product-image" />
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <h3 class="modifier-name">{{ prod.value.name }}</h3>
                    <div class="caption" v-if="webStore" v-html="prod.value.caption"></div>
                  </div>
                </li>
              </router-link>
            </ul>
            <ul v-else class="table wrapper layout">
              <router-link
                :to="`/web/${$route.params.id}/${cat.id.replace(/^.*:/, '')}/${prod.id.replace(/^.*:/, '')}`"
                class="long-section"
                v-for="prod in active_products_in_categories(cat)"
                :key="prod">
                <span v-if="prod.value.soldout" class="soldout" @click="productClick(prod.value)">Sold Out</span>
                <li :class="prod.value.soldout ? 'soldoutProd long-layout' : 'active long-layout'" :key="prod.value.id" @click="productClick(prod.value)">
                  <div v-if="siteSettings.imagesRequired || webStore">
                    <div v-if="prod.value.images">
                      <div>
                        <img loading="lazy" v-if="prod.value.images[0]" :alt="prod.value.name" :src="prod.value.images[0]" />
                        <img v-else class="product-image" />
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <h3 class="modifier-name">{{ prod.value.name }}</h3>
                    <div class="caption" v-if="webStore" v-html="prod.value.caption"></div>
                  </div>
                </li>
              </router-link>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <ItemModal :currentProduct="currentProduct" :open="open" :lineItem="lineItem" @closeItemModal="closeItemModal" />
  </Authenticated>
</template>

<script>
//import { defineAsyncComponent } from 'vue'
import Authenticated from "@/components/_layouts/Authenticated";
//import MiniCart from '@/components/Store/MiniCart.vue'
import createdMixin from "@/components/_mixins/createdMixin";
import ItemModal from "@/components/Store/ItemModal";
import { mapGetters } from "vuex";
import CartColumn from "./CartColumn.vue";

// import { ref } from "vue";

//const CheckoutModal = defineAsyncComponent(() => import('@/components/Store/CheckoutModal.vue'));

export default {
  name: "app",
  mixins: [createdMixin],
  components: {
    Authenticated,
    ItemModal,
    CartColumn,
  },
  emits: ["handleMenu"],
  computed: {
    ...mapGetters({
      categories: "categories",
      siteSettings: "siteSettings",
      localSettings: "localSettings",
      allProducts: "allProducts",
      webStore: "webStore",
      active_products_in_categories: "active_products_in_categories",
      cartCount: "cartCount",
      user: "user",
    }),
  },
  data() {
    return {
      channel: null,
      socket: null,
      openCheckout: false,
      open: false,
      currentProduct: null,
      showHistory: false,
      showCart: true,
      tableMode: false,
      lineItemHash: null,
      lineItem: null,
      show: false,
      change: 0,
      webCart: false,
      showUserModal: false,
      showUserAccountModal: false,
    };
  },
  created() {
    window.addEventListener("resize", this.screenChange);
  },
  mounted() {
    this.$store.commit("currPage", "store");
    this.screenChange();
    if (this.portrait) {
      window.addEventListener("scroll", this.onScroll);
    } else {
      document.querySelector(".main").addEventListener("scroll", this.onScroll);
    }

    if (Object.keys(this.$route.params).length > 0 && Object.keys(this.allProducts).length > 0) {
      this.checkParams();
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.screenChange);
    if (this.portrait) {
      window.removeEventListener("scroll", this.onScroll);
    }
  },
  methods: {
    onScroll() {
      let position = null;
      let main = document.querySelector(".main");
      let buttons = document.querySelector(".buttons");

      if (this.portrait) {
        position = window.pageYOffset / (main.scrollHeight - window.innerHeight);
      } else {
        position = main.scrollTop / (main.scrollHeight - buttons.clientHeight - main.clientHeight);
      }

      let scroll = position * (buttons.scrollWidth - buttons.clientWidth);
      document.querySelector(".buttons").scrollTo({ top: 0, left: scroll, behavior: "instant" });
    },
    screenChange() {
      let media = matchMedia("(orientation: portrait)");
      if (media.matches) {
        this.portrait = true;
      } else {
        this.portrait = false;
        this.showCart = true;
      }
      if (this.portrait) {
        this.showCart = false;
      }
    },
    topScroll() {
      let media = matchMedia("(orientation: portrait)");
      ////console.log(media.matches);
      if (media.matches) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        ////console.log(window.screenTop);
        if (window.screenTop < window.innerHeight) {
          this.webCart = !this.webCart;
        } else {
          this.webCart = true;
        }
      } else {
        this.webCart = !this.webCart;
      }
    },
    catScroll(pos) {
      const cat = document.getElementById(pos);
      if (cat !== null) {
        cat.scrollIntoView({
          behavior: "instant",
          block: "start",
          inline: "nearest",
        });
      }
    },
    closeItemModal() {
      this.lineItem = null;
    },
    loadOrderLineItem(lineItem) {
      var product = this.allProducts.find((product) => product.id === lineItem.id);
      this.currentProduct = product.value;

      this.lineItem = lineItem;
      this.open = !this.open;
    },
    productClick(prod) {
      if (!prod.soldout || !this.webStore) {
        this.currentProduct = prod;
        this.open = !this.open;
      }
    },
    checkParams() {
      if (this.$route.params.category && this.$route.params.product) {
        let cat = this.categories.find((cat) => cat.id === "category:" + this.$route.params.category);
        let prod = this.active_products_in_categories(cat).find((prod) => prod.value._id === "product:" + this.$route.params.product);
        this.productClick(prod.value);
      }
    },
  },
  watch: {
    open() {
      if (!this.open) {
        if (this.webStore) {
          this.$router.push({ path: "/web/" + this.$route.params.id });
        } else {
          this.$router.push({ path: "/store" });
        }
      }
    },
    allProducts() {
      this.checkParams();
    },
  },
};
</script>

<style scoped lang="scss">
@import "public/layout";
@import "public/wrapper";

.store-wrapper {
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;

  .side-scroll {
    overflow: auto;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
  }

  .main {
    height: 100vh;
    overflow-y: scroll;
    width: 90vw;
  }

  .cart-icon {
    display: none;
  }

  .price {
    float: right;
  }

  .history {
    padding: 0rem 1rem 0rem 1rem;
  }

  h2 {
    font-size: 1.2rem;
    margin: 0px;
    color: var(--text-colour);
  }

  .modifierBox {
    margin: 0.5rem;
    background-color: var(--primary-colour);
    color: var(--text-colour);
    overflow: hidden;
  }

  .soldout {
    font-weight: bold;
    font-size: 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--text-colour);
    position: absolute;
    border-radius: 0.25rem;
    padding: 0.4rem 0.8rem;
    margin: 0;
    background: var(--warning-colour);
    overflow: hidden;
    z-index: 1;
    text-align: center;
  }

  .soldoutProd {
    opacity: 0.5;

    &:hover {
      background-color: var(--secondary-colour) !important;
      cursor: default !important;
    }
  }

  .product-image {
    display: block;
    border: 0.1rem solid var(--primary-colour);
    border-radius: 0.25rem;
  }

  .text-center {
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;

    .modifier-name {
      text-align: center;
      margin: 0;
      font-size: small;
      padding-top: 0.5rem;
    }
    .caption {
      font-size: 0.8rem;
      padding-bottom: 0;
      p {
        margin: 0;
      }
    }
  }

  .long-layout {
    margin: 0.5rem;
    padding: 0.5rem;
    // width: 6rem;
    min-height: 1rem;
    max-height: 12rem;
    background-color: var(--primary-colour);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    // padding: 1rem;
    border: 2px;
    text-transform: capitalize;
    cursor: pointer;
    text-decoration: none;
    color: var(--text-colour);
    border-radius: 0.25rem;
    border-style: solid;
    border-color: var(--primary-colour);
    position: relative;

    img {
      height: 6rem;
      border-radius: 0.25rem;
      border: 1px solid var(--secondary-colour);
    }

    &:hover {
      background-color: var(--primary-colour);
      color: var(--text-colour);
      border-color: var(--primary-colour);
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-start;
    margin: 0rem 1rem 0rem 1rem;
    overflow: auto;
    position: sticky;
    top: 0;
    background-color: var(--primary-colour);
    cursor: pointer;
    z-index: 1000;

    .catergory-select {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5rem 0.5rem 0.5rem 0rem;
      height: 3rem;
      padding: 0rem 1rem;
      background-color: var(--action-colour);
      white-space: nowrap;

      .cat-name {
        text-align: center;
        padding: 0 0.2rem;
        color: #fff;
      }

      :hover {
        color: #484646;
      }

      color: var(--text-colour);
      border-radius: 0.35rem;
      border-style: solid;
      border-color: var(--primary-colour);
      //&:hover { background-color: var(--hover-colour); color: var(--text-colour); border-color: var(--primary-colour);}
    }
  }

  .drop-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }

  .layout {
    grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
    margin: 0;
    padding: 0;
  }

  .caption {
    text-align: center;
  }

  .table {
    display: flex;
    justify-content: space-between;
  }

  .background {
    background-color: var(--secondary-colour);
    padding: 1rem;
    margin: 0.5rem 1rem 1rem 1rem;
    border-radius: 0.5rem;
    //border-width: 3px;
  }

  img {
    width: 6rem;
  }

  .menu-section {
    display: inline-block;
    width: 14rem;
    scroll-snap-align: center;
  }

  .long-section {
    width: 100%;
    position: relative;
    text-decoration: none;
  }
}

@media only screen and (orientation: portrait) {
  .store-wrapper {
    display: block;

    .main {
      height: inherit;
      flex: 3;
      width: 100vw;
      overflow-y: unset;
    }

    .background {
      margin: 0rem 1rem 1rem 1rem;
    }

    .buttons {
      margin: 0rem 0rem;
    }
  }

  .menu-section {
    display: inline-block;
    width: 14rem;
    scroll-snap-align: center;
  }
}

@media only screen and (orientation: landscape) {
  .store-wrapper {
    .cart {
      flex: 1;
    }

    .main {
      flex: 2;
    }
  }
}
</style>
