<template>
  <div class="parent" v-show="webCart">
    <h2 class="title">
      <div v-if="!this.$store.getters.webStore">
        <span :class="showCart ? 'active' : 'dull'" @click="showCart = true">Order</span> |
        <span :class="!showCart ? 'active' : 'dull'" @click="showCart = false"> History</span>
      </div>
      <div v-else>
        <span>Cart</span>
      </div>
    </h2>
    <Cart v-if="showCart" @showWebOrderModal="openWebOrder = !openWebOrder" @showCheckoutModal="openCheckout = !openCheckout" @loadOrderLineItem="sendItem" />

    <HistoryColumn v-if="!showCart" @showTab="showTab" />
  </div>
  <CheckoutModal @showWebOrderModal="openWebOrder = !openWebOrder" :open="openCheckout" />
  <div class="cart-icon" v-if="webStore || portrait" @click="topScroll">
    <div class="cart-count">{{ cartCount }}</div>
   
    <font-awesome-icon class="icon" icon="shopping-cart" />
  </div>
  <WebOrderModal :open="openWebOrder" />
  

</template>
<script>
import { mapGetters } from "vuex";

import HistoryColumn from "../History/HistoryColumn.vue";
import Cart from "./Cart.vue";
import CheckoutModal from "./CheckoutModal.vue";
import WebOrderModal from "./WebOrderModal.vue";


// FIX: Hiding checkout on webstore on cart icon click

export default {
  name: "CartColumn",
  components: {
    HistoryColumn,
    Cart,
    CheckoutModal,
    WebOrderModal,
  },
  computed: {
    ...mapGetters({
      cartCount: "cartCount",
      webStore: "webStore",
    }),
  },
  emits: ["loadOrderLineItem"],
  props: [],
  data() {
    return {
      showCart: true,
      openCheckout: false,
      webCart: true,
      portrait: false,
      openWebOrder: false,
    };
  },
  methods: {
    sendItem(item) {
      this.$emit("loadOrderLineItem", item);
    },
    showTab(column) {
      column === "cart" ? (this.showCart = true) : false;
    },
    topScroll() {
      let media = matchMedia("(orientation: portrait)");
      ////console.log(media.matches);
      if (media.matches) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        ////console.log(window.screenTop);
        if (window.screenTop < window.innerHeight) {
          this.webCart = !this.webCart;
        } else {
          this.webCart = true;
        }
      } else {
        this.webCart = !this.webCart;
      }
    },
  },
  watch: {},
};
</script>
<style scoped lang="scss">
.parent {
  display: flex;
  flex-direction: column;

  padding: 0rem 1rem 0rem 1rem;
  max-height: 100vh;
  overflow-y: scroll;
  background-color: var(--secondary-colour);
  width: 20rem;
  border-radius: 1rem 0rem 0rem 0rem;
  .title {
    .active {
      color: var(--action-colour);
    }
    .dull {
      color: var(--text-colour);
    }
  }
}

.cart-icon {
  display: none;
}
@media only screen and (orientation: portrait) {
  .parent {
    width: inherit;
    padding-bottom: 1rem;
    flex: 1;
    border-radius: 0rem 0rem 1rem 1rem;
    max-height: inherit;
  }
  .cart-icon {
    position: fixed;
    top: auto;
    bottom: 1rem;
    right: 1rem;
    color: var(--action-colour);
    cursor: pointer;
    background-color: var(--secondary-colour);
    border: 0.25rem solid var(--primary-colour);
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;

    .icon {
      width: 2rem;
    }
    .cart-count {
      position: absolute;
      top: -10px;
      right: -10px;
      background-color: var(--warning-colour);
      color: var(--secondary-text-colour);
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
    }
  }
}
</style>
